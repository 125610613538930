import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import { TextFieldSearch } from "../../../../../../../components/TextField";
import { findAllProcedimentos } from "../../../../../../../services/OrcamentoService";
import string from "../../../../../../../utils/string";
import { applyCurrencyMask } from "../../../../../../../utils/CurrencyMask";
import { ReactSelect } from "../../../../../../../components/Select/SelectSearch";
import InputForm from "../../../../../../../components/Input/InputForm";

const ProcedimentosOrtopedicos = observer(
  ({
    classes,
    handleFieldModal,
    orcamentoProcedimentos,
    orcamentoStore,
    orcamento,
  }) => {
    const { convenio, numero } = orcamento || {};

    const [revestimentos,setRevestimentos]=useState([])

    useEffect(() => {
      calculaProcedimentos();
    }, [orcamentoProcedimentos]);

    useEffect(() => {
      findRevestimentos()
    }, [orcamentoStore.tipoRevestimento]);

    const findRevestimentos = async () => {
      let revestimentos
      let pageableDTO = {
        sortDir: "ASC",
        sortField: "nome",
        pageNumber: 0,
        pageSize: 10,
      };
      const variables = {
        ativo: true,
        pageableDTO,
        nome: '',
        tiposProcedimentos: orcamentoStore.tipoRevestimento.value === 'REVESTIMENTO_EVA' ? 'REVESTIMENTO_EVA' : 'REVESTIMENTO_TECIDO',

      }
      try {
        revestimentos = await findAllProcedimentos({ ...variables })
      } catch (error) {
        console.error(error)
      }
      setRevestimentos(revestimentos?.content)
    }

    const calculaProcedimentos = () => {
      const soma = orcamentoProcedimentos?.reduce(
        (accumulator, currentValue) => {
          const valor =
            typeof currentValue?.valorUnitario === "string"
              ? string.currencyMaskToFloat(currentValue?.valorUnitario)
              : currentValue?.valorUnitario;
          return accumulator + valor;
        },
        0
      );
      orcamentoStore.orcamento.valor = soma;
    };

    const handleAddProcedimento = (procedimento) => {
      orcamentoStore.orcamento.orcamentoProcedimentos = []

      const valorUnitario =
        procedimento?.convenioProcedimentos?.find(
          (convenioProcedimento) =>
            convenioProcedimento?.convenio?.id === convenio?.value
        )?.valor || 0;

      const selectedProcedimentos = [
        {
          procedimento,
          valorUnitario,
        },
      ];
      handleFieldModal("orcamentoProcedimentos", selectedProcedimentos);
    };

    const loadProcedimentos = async (search, loadedOptions, { page }) => {
      return handleLoadMoreProcedimentos({
        search,
        data: { page },
      });
    };

    const onChangeTipoRevestimento = (e) => {
      if (orcamentoStore.orcamento.orcamentoProcedimentos.length > 1) {
        orcamentoStore.orcamento.orcamentoProcedimentos.pop()
      }

      orcamentoStore.onChangeTipoRevestimento(e)
    }

    const handleLoadMoreProcedimentos = async ({ search, data }) => {
      let pageableDTO = {
        sortDir: "ASC",
        sortField: "nome",
        pageNumber: data.page,
        pageSize: 20,
      };

      const response = await findAllProcedimentos({
        nome: search,
        ativo: true,
        pageableDTO,
        tiposProcedimentos: ["PALMILHA"],
      });

      const { content, last } = response;

      if (content) {
        return {
          options: content,
          hasMore: !last,
          additional: {
            page: data.page + 1,
          },
        };
      }
    };

    const onChangeRevestimento = (e) => {
      const procedimentos = orcamentoStore?.orcamento?.orcamentoProcedimentos || []

      const novoProcedimento = {
        procedimento: {
          ...e,
        },
        valorUnitario: e?.convenioProcedimentos[0]?.valor || 0
      }

      if (orcamentoStore.orcamento.orcamentoProcedimentos.length > 1) {
        orcamentoStore.orcamento.orcamentoProcedimentos.pop()
      }

      const procedimentosOrcamento = [...procedimentos, novoProcedimento]
      orcamentoStore.orcamento.orcamentoProcedimentos = procedimentosOrcamento
    }

    const handleNumero = (value) => {
      handleFieldModal("numero", value);
    }

    const procedimentoSelected =
    orcamentoStore?.orcamento?.orcamentoProcedimentos?.find(
      (item) =>
        item?.procedimento?.tiposProcedimentos?.[0] === "PALMILHA"
    ) || null;

    const revestimento =
    orcamentoStore?.orcamento?.orcamentoProcedimentos?.find(
      (item) =>
        ["REVESTIMENTO_TECIDO", "REVESTIMENTO_EVA"].includes(
          item?.procedimento?.tiposProcedimentos?.[0]
        )
    ) || null;

    return (
      <div className={classes.container}>
        <div className={classes.contentProcedimentos}>
          <div className={classes.contentNumero}>
            <label className={classes.title}>Numeração*</label>
            <InputForm
              value={numero}
              onChange={e => handleNumero(e.target.value)}
              type={'number'}
              className={classes.inputContainer}
            />
          </div>
          <label className={classes.title}>Tipo modelo*</label>
          <TextFieldSearch
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            placeholder={"Selecione o item"}
            onChange={(e, index) => handleAddProcedimento(e, index)}
            value={procedimentoSelected}
            menuPlacement={"auto"}
            isClearable
            getOptionLabel={(option) => option.nome || option.procedimento?.nome}
            getOptionValue={(option) => option.id || option.procedimento?.id}
            withPaginate
            loadOptions={loadProcedimentos}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
          <div className={classes.reactSelects}>
            <div className={classes.contentSelect}>
              <label>Tipo revestimento</label>
              <ReactSelect className={classes.select}
                options={orcamentoStore.tiposDeRevestimento}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={(e) => onChangeTipoRevestimento(e)}
                value={orcamentoStore.tipoRevestimento}
              />
            </div>

            <div>
              <label>{`Revestimento ${orcamentoStore.tipoRevestimento?.label}*`}</label>
              <ReactSelect
                className={classes.select}
                options={revestimentos || []}
                value={revestimento || {}}
                getOptionLabel={(option) => option.nome || option.procedimento?.nome}
                getOptionValue={(option) => option.id || option.procedimento?.id}
                onChange={(e) => onChangeRevestimento(e)}
              />
            </div>
          </div>
        </div>
        <div className={classes.containerTotal}>
          <div className={classes.contentTotal}>
            <label className={classes.total}> Total: </label>
            <label className={classes.totalValue}>
              {typeof orcamento.valor === "string"
                ? orcamento.valor
                : applyCurrencyMask(orcamento.valor)}
            </label>
          </div>
        </div>
      </div>
    );
  }
);

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    borderRadius: "10px",
    height: 'calc(100% - 100px)',
    justifyContent: 'space-between',
    color:"#868686",

    "& thead tr": {
      textAlign: "left",
      height: "32px",
    },
  },
  tableRoot: {
    height: "100%",
  },
  contentProcedimentos: {
    margin: "16px 16px 0",
  },
  notchedOutline: {
    border: "0",
  },
  contentTotal: {
    height: "40px",
    paddingRight: "8px",
    textAlign: "end",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    gap: "4px",
  },
  total: {
    fontWeight: 600,
    fontSize: 16,
  },
  totalValue: {
    fontWeight: 600,
    fontSize: 18,
  },
  table: {
    height: "100%",
    overflow: "auto",
  },
  tableMenor: {
    maxHeight: "130px",
  },
  containerTotal: {
    display: "flex",
    justifyContent: "end",
    paddingLeft: "10px",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
  },
  select: {
    width: '100%'
  },
  reactSelects: {
    gap: '10px',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column'
  },
  contentSelect: {
    gap: '15px'
  },
  inputContainer: {
    width: '100%',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '30px',
    '& > div': {
      height: '30px',
      '& > input': {
        height: '30px',
        border: 'none',
      }
    }
  },
  contentNumero: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  }
};

const ProcedimentosOrtopedicosWithStyles = withStyles(styles)(
  ProcedimentosOrtopedicos
);
export default inject("orcamentoStore")(ProcedimentosOrtopedicosWithStyles);
